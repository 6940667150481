import courseNames from './course-names';
import schema from './schema';

export default {
  algolia: {
    applicationId: 'TO2F04TXTS',
    searchOnlyApiKey: 'e37dcec3965d1cabde303b17e65d25f0',
    prefix: 'calligraphy-prod-2018',
  },
  analytics: {
    trackingId: 'UA-6859198-16',
  },
  courseNames,
  notifications: {
    ADMIN: 'admin',
    MESSAGE: 'message',
  },
  schema,
  shopify: {
    url: 'https://quiver-one.myshopify.com',
  },
  version: 'X_VERSION',
};
