import prodEnvironment from './environment';
export default {
  ...prodEnvironment,
  algolia: {
    applicationId: 'TO2F04TXTS',
    searchOnlyApiKey: 'e37dcec3965d1cabde303b17e65d25f0',
    prefix: 'calligraphy-dev-2018',
  },
  isDev: true,
};
