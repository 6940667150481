export default {
  'beginning-brush-calligraphy-class': 'Brush Calligraphy',
  'brush-calligraphy-class': 'Brush Calligraphy',
  'BRUSHKIT001': 'Brush Calligraphy',
  'chalk-lettering-with-skyler-chubak': 'Chalk Lettering',
  'keepsake-journaling': 'Keepsake Journaling',
  'lettering-class': 'Lettering',
  'lettering-class-bundle': 'Lettering',
  'modern-calligraphy-class': 'Modern Calligraphy',
  'MODERNKIT': 'Modern Calligraphy',
  'spencerian-class': 'Spencerian',
};
